@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

$primary: #00a57c;
$primary-100: rgba($primary, 20%);
$primary-800: rgba($primary, 70%);
$primary-light: #d9f4e3;
$secondary: #b2b2b2;
$light: #daebf8;
$white: #f5f5f5;
$danger: #cf0a0a;
$dark: #333333;
$black-100: rgba($dark, 10%);
$font-family-sans-serif: "Poppins", sans-serif;
$default-border-radius: 10px;

.rounded-10 {
    border-radius: $default-border-radius;
}

// Header

.c-header{

    .notifications-item {
        border-bottom: 1px solid #eee;
        padding: 9px 20px;
        margin-bottom: 0px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        .text {
            flex-grow: 2;
            p {
                color: #aaa;
                font-size: 12px;
                text-decoration: none;
            }
        }
    }

    .notifications {
        width: 320px;
        height: auto;
        display: none;
        position: absolute;
        top: 45px;
        right: 6px;
        border-radius: 5px 0px 5px 5px;
        background-color: #fff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .message-center {
        max-height: 600px;
        overflow-y: scroll;
    }

    #getNotis a:hover,
    #getNotis a {
        text-decoration: none;
    }
}

//activity log
.activity-description
{
    width:4px;
    height:10px;
    display:inline-block;
    margin-right:6px;
}
// For Login

.login-form {
    .form-control {
        background-color: $white;
        border-radius: $default-border-radius;
        height: 40px;
    }
}

body {
    font-family: $font-family-sans-serif;
}

.btn-alt-primary {
    background-color: rgba($primary, 100%);
    color: #f5f5f5;

    &:hover {
        background-color: rgba($primary, 90%);
        color: #f5f5f5;
    }
}

.select2-selection__rendered,
.select2-selection__choice,
.select2-results__options,
.select2-results__option {
    font-size: 14px !important;
    vertical-align: middle;
    margin-top: 2px;
}

.select2-container--bootstrap-5 {
    &.select2-container--focus {
        .select2-selection {
            border-color: $primary;
            box-shadow: none !important;
        }
    }
    &.select2-container--open {
        .select2-selection {
            border-color: $primary;
            box-shadow: none !important;
        }
    }
    .select2-dropdown{
        border-color: $primary;
        .select2-search{
            .select2-search__field:focus{
                border-color: $primary;
                box-shadow: none !important;
            }
        }
    }
}

.alert.alert-danger {
    ul {
        margin-bottom: 0;
    }
}

.title-text {
    color: $primary;
}

html {
    &:not([dir="rtl"]) {
        .c-sidebar-nav-dropdown-items {
            .c-sidebar-nav-link {
                padding-left: 70px;
            }

            .c-sidebar-nav-dropdown-toggle {
                padding-left: 70px;
            }
        }
    }
}

.ck-editor__editable {
    min-height: 150px;
}

textarea {
    min-height: 150px;
}

.datatable {
    width: 100% !important;
}

div.dataTables_wrapper {
    div.dataTables_processing {
        background: transparent url("../images/loading.gif") no-repeat top left;
        width: 100px;
        height: 100px;
        top: -120px;
        text-indent: -99999px;
        margin-top: auto;
        border: none;
        background-color: white;

        div {
            display: none;
        }
    }
    div.dataTables_paginate {
        ul.pagination {
            .active {
                background-color: $primary;
                .page-link {
                    background-color: transparent;
                    color: white !important;
                    border-color: $primary;
                }
            }
        }
    }
}

div.dataTables_processing {
    > div {
        &:last-child {
            > div {
                display: none;
            }
        }
    }
}

.table-id {
    width: 30px;
}

table.dataTable {
    tbody {
        td.select-checkbox {
            &::before {
                top: 50%;
            }

            &::after {
                top: 50%;
            }
        }

        th.select-checkbox {
            &::before {
                top: 50%;
            }

            &::after {
                top: 50%;
            }
        }
    }

    thead {
        th {
            border-bottom: 2px solid #c8ced3;
            font-weight: 600;
        }

        .sorting {
            background-image: none;
        }

        .sorting_asc {
            background-image: none;
        }

        .sorting_desc {
            background-image: none;
        }
    }
}

.dataTables_length {
    margin-bottom: 0.333em;
    margin-top: 0.2rem;
    float: left;
}

.dataTables_filter {
    margin-bottom: 0.333em;
    margin-top: 0.2rem;
    float: left;
    margin-right: 0.2rem;
    float: right;
}

.dt-buttons {
    margin-bottom: 0.333em;
    margin-top: 0.2rem;
    float: left;
    margin-left: 10px;

    .btn {
        margin-left: 0.333em;
        border-radius: 0;
    }
}

.table.datatable {
    box-sizing: border-box;
    border-collapse: collapse;
}

.dataTables_wrapper.no-footer {
    .dataTables_scrollBody {
        border-bottom: 1px solid #c8ced3;
    }
}

.has-error {
    .invalid-feedback {
        display: block !important;
    }
}

.btn-info {
    color: white;
}

.badge-info {
    color: white;
}

.sidebar {
    .nav-item {
        cursor: pointer;
    }
}

.btn-default {
    color: #23282c;
    background-color: #f0f3f5;
    border-color: #f0f3f5;

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(209, 213, 215, 0.5);
    }

    &:hover {
        color: #23282c;
        background-color: #d9e1e6;
        border-color: #d1dbe1;
    }
}

.btn-default.focus {
    box-shadow: 0 0 0 0.2rem rgba(209, 213, 215, 0.5);
}

.btn-group-xs {
    > .btn {
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
    }
}

.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.searchable-title {
    font-weight: bold;
}

.searchable-fields {
    padding-left: 5px;
}

.searchable-link {
    padding: 0 5px 0 5px;

    &:hover {
        cursor: pointer;
        background: #eaeaea;
    }
}

.form-group {
    .required {
        &::after {
            content: " *";
            color: $danger;
        }
    }
}

.form-check.is-invalid {
    ~ {
        .invalid-feedback {
            display: block;
        }
    }
}

.c-sidebar-brand {
    color: #fff;
    background: white !important;
    padding: 10px;

    .c-sidebar-brand-full {
        &:hover {
            color: inherit;
        }
    }
}

.custom-select.form-control-sm {
    padding: 0.25rem 1.5rem;
}

// For Badge
.big-badge {
    font-size: 13px;
    font-weight: 500;
    padding: 5px 9px;
}

// For Card
.info-card {
    display: block;

    .card-body {
        padding: 20px 15px;
    }

    .card-header {
        padding: 16px;
    }
}

.card-primary {
    background-color: $primary-100;
}

.info-box {
    display: flex;

    div {
        width: 50%;
    }
}

// For CoreUI AdminDashboard
.c-app {
    background: linear-gradient(180deg, #daebf8 0%, #d9f4e3 100%);

    .c-sidebar {
        background-color: $primary;

        .c-sidebar-nav-link,
        .c-sidebar-nav-dropdown-toggle {
            color: $white;

            &:hover {
                background-color: $black-100;
            }

            &.c-active {
                background-color: $black-100;
            }
        }

        .c-sidebar-nav-icon {
            color: rgba($white, 0.8);
        }
    }

    .c-primary {
        color: $primary;
    }
}

.home-body{
    #table-card {
        max-height: 380px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 0.3em;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary;
            outline: 1px solid $secondary;
        }
    }
}

// For  Payment Selected

.select-status {
    width: 102px;
    border: 1px solid $dark;
    border-radius: 5px;
    padding-left: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: rgba($dark, 10%);

    &:focus {
        outline: none;
    }
}

//For All Datatable Search

.search {
    border: 1px solid $dark;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;

    &:focus {
        outline: none;
    }
}

// .popularTest{
//     .dataTables_filter{
//         display: none;
//     }
// }

// For Booking

.booking-title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

//For Choose Test

#show {
    height: 300px;
    overflow-y: auto;

    .selected-test {
        text-decoration: none;
        list-style: none;
        padding-left: 0px;

        .choose-test-list {
            border: 1px solid $secondary;
            margin: auto;
            display: flex;
            margin-bottom: 10px;
            align-items: center;
            justify-content: space-between;
            padding: 8px 18px;
            position: relative;
            cursor: pointer;
        }
    }
}

.bookingStepTable {
    tbody {
        tr {
            td {
                padding: 0px !important;
            }
            .row-action,
            td:first-child {
                padding: 0.5rem 0.5rem;
            }

            .row-action {
                cursor: pointer;
            }
        }
    }
}

.hide {
    .dataTables_filter {
        display: none;
    }
}

// For Dashboard

.report-card {
    height: 110px;
    h6 {
        width: 120px;
    }

    .total-number {
        font-size: 25px;
        font-weight: 600;
    }

    .icon-border {
        background-color: $primary;
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 50%;
        z-index: 1;

        i {
            color: $white;
        }

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            right: 0px;
            background-color: $primary;
            opacity: 0.3;
            border-radius: 100%;
            z-index: -1;
            animation: animate 1s linear infinite alternate;
            transform: scale(1.3);
        }
    }
}

.form-box {
    display: flex;
    justify-content: end;
    align-items: flex-end;
    position: relative;
}

.pie-card {
    height: 350px;
    canvas {
        margin: auto;
        width: 100%;
    }
}

// Form Multi Step

.step-container {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding-bottom: 15px;
    border-radius: 15px;

    .step {
        position: absolute;
        top: 0px;
        width: 2rem;
        height: 2rem;
        background-color: transparent;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            color: #bdbdbd;
            font-size: 10px;
        }

        .step-link {
            text-decoration: none;
            white-space: nowrap;
            position: absolute;
            top: 100%;
            color: $dark;
        }
    }

    .step-active {
        background-color: $primary;

        i {
            color: white;
        }

        .step-link {
            color: $primary;
        }
    }
}

// Sweet Alert Css
.swal2-popup.swal2-toast{
    background: $primary !important;
    color: white;
    font-size: 14px;
}

.swal2-styled.swal2-confirm{
    background: $primary !important;
    &:focus{
        box-shadow: unset !important;
    }
}

// For Choose Patient

.pateintDatatable {
    .db-row {
        cursor: pointer;
    }
}

.dropdown-toggle:after{
    display: none;
}



@media screen and (max-width: 576px) {
    .booking-title-box {
        flex-flow: column wrap;
        gap: 20px;
    }
}

@keyframes animate {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.3);
    }
}
